<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @keyup.enter.native="getMstfsNow()"
        >
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_no"
                  clearable
                  placeholder="请填写物料编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_name"
                  clearable
                  placeholder="请填写物料名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="出入库类型：">
                <el-select
                  size="small"
                  v-model="searchForm.mstf_type"
                  placeholder="请选择出入库类型"
                  clearable
                >
                  <el-option
                    v-for="item in mstfType"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号：">
                <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" >
              <el-form-item label="库位：">
                <el-select
                  size="small"
                  v-model="searchForm.mtqr_bin"
                  placeholder="请选择库位"
                  clearable
                >
                  <el-option
                    v-for="item in binList"
                    :key="item.id"
                    :label="item.param1"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="userInfo.acct_id==1">
               <el-form-item label="所属账套：" label-width="82px">
              <el-select
                v-model="searchForm.acct_id"
                filterable
                placeholder="请选择所属账套"
                clearable
                size="small"
                @change="getMstfsNow()"
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="录入时间：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getMstfsNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--  v-if="userInfo.acct_id!=1" -->
      <div class="vd_button_group vg_mtb_16"  >
        <el-button
          v-if="userInfo.acct_id !== 1"
          type="primary"
          size="small"
          @click="addRow(0)"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增入库</el-button
        >
        <el-button
          v-if="userInfo.acct_id !== 1"
          type="warning"
          size="small"
          @click="addRow(1)"
          :disabled="!btn.add"
          ><i class="el-icon-plus"></i> 新增出库</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :data="tableData"
            border
            v-loading="loadingFlag"
          >
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <!--    
                 :src="helper.picUrl(scope.row.imge_url,'m')"
                  -->
                  <el-image
                    v-if="scope.row.imge_url"
                    class="vd_elimg"
                    :src="formatPic(scope,'s')"
                    fit="fill"
                  >
                  </el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="物料名称"
              prop="mtrl_name"
              show-overflow-tooltip
            />
            <el-table-column
              label="物料类型"
              prop="mtrl_type"
              :formatter="formatLeavType"
            />
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{'vd_div':scope.row.colr_html}" :style="{'background-color': scope.row.colr_html}"></div>
                    <span>{{ scope.row.mtrl_color}}</span> 
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="出入库类型" prop="mstf_type">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.mstf_type === 0 || scope.row.mstf_type === 1"
                >
                  {{ scope.row.mstf_type | formatMstfType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="mtqr_bin_no">
              <template slot-scope="scope">
                <span v-if="scope.row.mtqr_bin_no">
                  {{ scope.row.mtqr_bin_no }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="物料数量"
              prop="mtrl_num"
              :formatter="formatMtrlNum"
            />
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="录入人"
              prop="stff_name"
              show-overflow-tooltip
              :formatter="helper.personCombina"
            />
            <el-table-column
              label="录入时间"
              prop="create_time"
              :formatter="formatDate"
            />
            <el-table-column label="所属账套" prop="acct_no" v-if="userInfo.acct_id===1"/>
            <el-table-column label="所属公司" prop="cptt_name" show-overflow-tooltip v-if="userInfo.acct_id==1"/>
            <!-- <el-table-column label="单据状态" :show-overflow-tooltip='true' align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { mstfAPI } from "@api/modules/mstf";
import { optnAPI } from "@api/modules/optn";
import pubPagination from "@/components/common/pubPagination";
import { acctAPI } from "@api/modules/acct";
import helper from "@assets/js/helper.js"

export default {
  name: "MstfList",
  components: {
    pubPagination,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mstf_type: null,
        mtrl_no: null,
        mtrl_name:null,
        mtqr_bin: null,
        mtrl_color:null,
        acct_id:2,
        timeValue: {
          startTime: null,
          endTime: null,
        },
      },
      totalPage: 0,
      btn: {},
      loadingFlag: true,
      currentPage: 1,
      binList: [],
      mstfType: [
        { id: 0, label: "入库" },
        { id: 1, label: "出库" },
      ],
      userInfo:null,
      acctList:[]
    };
  },
  created() {
    this.initData();
  },
  filters: {
    // 出入库类型
    formatMstfType(row) {
      if (row === 0) {
        return "入库";
      } else if (row === 1) {
        return "出库";
      }
    },
    formaUnitH(row){
      return helper.reservedDigits(row)+'毫米'
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/mstf_add" || from.path === "/mstf_addO") {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.loadingFlag = true
      
      this.getBinList();
      this.getUserInfo()
  
       if(this.userInfo.acct_id==1){
       this.getAcct()
     }else{
       this.getMstfsList()
     }
    },
    getUserInfo(){
      this.userInfo = this.$cookies.get('userInfo')
    },
     //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            setTimeout(()=>{
      this.getMstfsList()
            },500)
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then((res) => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取出入库信息
    getMstfsList() {
      if(this.userInfo.acct_id!=1){
        this.searchForm.acct_id = null
      }
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(mstfAPI.getMstfs, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_name:this.searchForm.mtrl_name,
        mtqr_bin: this.searchForm.mtqr_bin,
        mstf_type: this.searchForm.mstf_type,
        mtrl_color:this.searchForm.mtrl_color,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage,
        acct_id:this.searchForm.acct_id
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg || "";
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mstf_type: null,
        mtrl_no: null,
        mtrl_name:null,
        mtqr_bin: null,
        mtrl_color:null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
      };
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMstfsNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // //新增
    addRow(val) {
      const permId = this.$route.query.perm_id;
      if (val === 0) {
        this.jump(`/mstf_add?perm_id=${permId}`);
      } else if (val === 1) {
        this.jump(`/mstf_addO?perm_id=${permId}`);
      }
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return "原面料";
      } else if (row.mtrl_type === 1) {
        return "辅料";
      } else if (row.mtrl_type === 2) {
        return "包材";
      } else if (row.mtrl_type === 3) {
        return "加工面料";
      }
    },
    formatMtrlNum(row) {
      return this.helper.haveFour(row.mtrl_num) + " " + row.mtrl_unit;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
      return this.helper.picUrl(
        scope.row.imge_url,
        type,
        updateTime,
        scope.row.imge_source
      );
    },
  },
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
.vd_div{
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis{
  display: flex;
}
</style>
